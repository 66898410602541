import React, { Component } from 'react'
import PropTypes from 'prop-types'
import device from 'current-device'
import classNames from 'classnames'

import './App.scss'

import AssignmentContainer from '../../containers/AssignmentContainer'
import StartContainer from '../../containers/StartContainer'
import Viewer from '../Viewer/Viewer'
import Timeline from '../Timeline/Timeline'
import LocaleContext from '../locale/LocaleContext'
import AboutButton from '../Buttons/AboutButton'
import ShareRoundButton from '../Buttons/ShareRoundButton'
import history from '../../utils/history'
import TitleSequence from '../TitleSequence/TitleSequence'
import EndCredits from '../EndCredits/EndCredits'
import TapHint from '../Hint/TapHint'
import Error from '../Error/Error'
import CameraContainer from '../../containers/CameraContainer'
import ShareOverlay from '../ShareOverlay/ShareOverlay'
import AboutContainer from '../../containers/AboutContainer'
import AudioHint from '../Hint/AudioHint'
import ExplanationOverlay from '../ExplanationOverlay/ExplanationOverlay'
import DesktopHint from '../Hint/DesktopHint'

class App extends Component {
  state = {
    presentationMode: false,
  }

  constructor(props) {
    super(props)

    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.openAbout = this.openAbout.bind(this)
  }

  componentWillMount() {
    const { loadDependencies } = this.props
    loadDependencies()

    document.addEventListener('keyup', this.handleKeyPress)

    // go into presentation mode when we're in the HNI installation
    if (window.location.pathname.replace(/\//gi, '') === 'hni') {
      this.togglePresentationMode()
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.handleKeyPress)
  }

  handleKeyPress(e) {
    switch (e.key) {
      case 'p':
        this.togglePresentationMode()
        break
      default:
        break
    }
  }

  togglePresentationMode() {
    this.setState({
      presentationMode: !this.state.presentationMode,
    })
  }

  openAbout() {
    const { locale } = this.props.copy
    const current = window.location.pathname.replace('/', '')
    const slug = locale === 'nl' ? 'over' : 'about'
    const aboutUrl = current ? `${current}/${slug}` : slug
    history.push(aboutUrl)
  }

  render() {
    const {
      selectedObject,
      unselectObject,
      showStartScreen,
      isCameraReady,
      copy,
      isAboutVisible,
      isShareVisible,
      share,
      hideShare,
      hideAbout,
      didTap,
      didDeclineCamera,
      uiBottom,
      isDeviceSupported,
      showExplanation,
      hideExplanation,
      shareNatively,
    } = this.props
    const hasSelection = !!selectedObject

    const { presentationMode } = this.state
    const isUiVisible = isDeviceSupported && !showStartScreen && !hasSelection && !presentationMode && !isShareVisible

    const className = classNames('App', presentationMode && 'installation')

    return (
      <LocaleContext.Provider value={copy}>
        <div className={className}>
          <Viewer />
          <CameraContainer />

          {(isUiVisible || presentationMode) && (
            <div className="titles-overlay" style={{ bottom: uiBottom }}>
              <TitleSequence />
              <EndCredits shareNatively={shareNatively} />
            </div>
          )}

          {!isDeviceSupported && (
            <Error
              canRestart={false}
              messageId={device.android() ? 'error.notSupportedAndroid' : 'error.notSupported'}
            />
          )}

          {isDeviceSupported && (
            <div className="ui" style={{ bottom: uiBottom }}>
              {showStartScreen && <StartContainer />}
              {!showStartScreen && (
                <>
                  {hasSelection
                    && isCameraReady && <AssignmentContainer presentation={presentationMode} />}

                  {isUiVisible && (
                    <>
                      {!device.desktop() && <TapHint didTap={didTap} />}
                      <AudioHint />
                      {device.desktop() && <DesktopHint />}
                      <Timeline />
                      <div className="NavButtons">
                        <ShareRoundButton onTap={share} />
                        <AboutButton onTap={this.openAbout} />
                      </div>
                    </>
                  )}
                </>
              )}
              {isAboutVisible && <AboutContainer onClose={hideAbout} />}
              {isShareVisible && <ShareOverlay onClose={hideShare} />}
              {hasSelection
                && didDeclineCamera && (
                  <Error
                    canRestart={!device.android()}
                    messageId={device.android() ? 'error.cameraAndroid' : 'error.camera'}
                    onClose={unselectObject}
                  />
              )}
              {showExplanation && <ExplanationOverlay onContinue={hideExplanation} />}
            </div>
          )}
        </div>
      </LocaleContext.Provider>
    )
  }
}

App.propTypes = {
  isCameraReady: PropTypes.bool.isRequired,
  showStartScreen: PropTypes.bool.isRequired,
  share: PropTypes.func.isRequired,
  hideShare: PropTypes.func.isRequired,
  hideAbout: PropTypes.func.isRequired,
  loadDependencies: PropTypes.func.isRequired,
  isAboutVisible: PropTypes.bool.isRequired,
  isShareVisible: PropTypes.bool.isRequired,
  selectedObject: PropTypes.string,
  unselectObject: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  copy: PropTypes.object.isRequired,
  didTap: PropTypes.bool.isRequired,
  didDeclineCamera: PropTypes.bool.isRequired,
  uiBottom: PropTypes.number.isRequired,
  isDeviceSupported: PropTypes.bool.isRequired,
  showExplanation: PropTypes.bool.isRequired,
  hideExplanation: PropTypes.func.isRequired,
  shareNatively: PropTypes.func.isRequired,
}

App.defaultProps = {
  selectedObject: null,
}

export default App
