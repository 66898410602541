import mitt from 'mitt'
import { Howl, Howler } from 'howler'

import store from './store'
import constants from './actions/constants'

class AudioPlayer {
  time = 0
  duration = 0
  isLoaded = false
  isPaused = false
  isPlaying = false

  constructor() {
    Object.assign(this, mitt())

    this.usingWebAudio = Howler.usingWebAudio

    this.sound = new Howl({
      src: [`${process.env.PUBLIC_URL}/sounds/lake-of-pavement.mp3`],
    })

    this.attachEvents()
  }

  attachEvents() {
    this.sound.once('load', () => {
      this.isLoaded = true
      this.duration = this.sound.duration()
      // TODO: do we need audio state in Redux?
      store.dispatch({ type: constants.AUDIO_LOADED })
      this.emit('load')
    })

    this.sound.on('end', () => {
      this.isPlaying = false
      this.isPaused = false
      this.isFinished = true
      this.emit('end')
    })

    this.sound.on('play', () => {
      this.isPlaying = true
      this.isPaused = false
      this.isFinished = false
      this.emit('play')
    })

    this.sound.on('pause', () => {
      this.isPlaying = false
      this.isPaused = true
      this.emit('pause')
    })
  }

  play() {
    if (!this.sound.playing() && !this.isFinished) {
      this.sound.play()
    } else {
      console.error('Sound is already playing')
    }
  }

  pause() {
    if (this.sound.playing()) {
      this.sound.pause()
    } else {
      console.error('Sound is already paused')
    }
  }

  seek(time) {
    this.seekDiff = time - this.time
    this.sound.seek(time)
    if (!this.sound.playing()) this.time = time
  }

  gotoTime(time) {
    if (time >= 0 && time <= this.duration) {
      this.isGoto = true
      this.seek(time)
    }
  }

  mute(isMuted) {
    this.sound.mute(isMuted)
  }

  restart() {
    this.time = 0
    this.isGoto = false
    this.isFinished = false
    this.isPaused = false
    this.sound.rate(1)
    if (this.sound.playing()) {
      this.sound.seek(0)
    } else {
      this.sound.play()
    }
  }

  reset() {
    this.time = 0
    this.isGoto = false
    this.isFinished = false
    this.isPaused = false
    this.sound.seek(0)
    this.sound.rate(1)
  }

  tick() {
    this.time = this.sound.state() === 'loaded' ? this.sound.seek() : 0
    this.progress = this.duration ? this.time / this.duration : 0
  }
}

export default new AudioPlayer()
