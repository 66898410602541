import DetectRTC from 'detectrtc'
import * as Sentry from '@sentry/browser'
import axios from 'axios'
import dataURLtoBlob from 'blueimp-canvas-to-blob'
import ReactGA from 'react-ga'

import constants from './constants'
import viewer from '../viewer'
import TextureManager from '../utils/TextureManager'
import { loadVersion } from './version'
import PlaybackController from '../utils/PlaybackController'
import WebcamScene from '../animation/WebcamScene'
import { getInstruction } from '../selectors'
import { saveUser } from './user'
import history from '../utils/history'
import audio from '../audio'
import supportsWebShare from '../utils/supportsWebShare'
import slugToTitle from '../utils/slugToTitle'

export const processUrl = pathname => (dispatch, getState) => {
  console.log('changed url', pathname)
  const { version, app } = getState()

  const url = pathname.replace(/^\/+/g, '')
  const allowed = ['about', 'over']
  const parts = url.split('/')

  const hasAbout = allowed.includes(parts[0]) || allowed.includes(parts[1])
  let versionName = parts[0] || 'frontpage'
  if (hasAbout && allowed.includes(parts[0])) versionName = 'frontpage'

  // make sure we load the frontpage when we're in the HNI instllation
  if (versionName === 'hni') versionName = 'frontpage'

  if (!version.textures || (versionName && version.name !== versionName)) {
    dispatch(loadVersion(versionName || undefined))

    PlaybackController.reset()
    dispatch({ type: constants.RESTART_APP })
  }

  if (hasAbout) {
    PlaybackController.pause()
    dispatch({ type: constants.SHOW_ABOUT })
    ReactGA.event({
      category: 'about',
      action: 'opened',
    })
  } else {
    if (app.isAboutVisible && !app.showStartScreen) PlaybackController.play()
    dispatch({ type: constants.HIDE_ABOUT })
  }
}

export const checkDeviceSupport = () => dispatch => {
  DetectRTC.load(() => {
    if (DetectRTC.isMobileDevice && !DetectRTC.hasWebcam) {
      dispatch({ type: constants.DEVICE_NOT_SUPPORTED })
      ReactGA.event({
        category: 'app',
        action: 'not supported',
      })
    }
  })
}

export const loadDependencies = () => dispatch => {
  dispatch(checkDeviceSupport())
  dispatch(processUrl(window.location.pathname))
}

export const start = () => (dispatch, getState) => {
  dispatch({ type: constants.START_APP })
  const { isMuted } = getState().app
  const isInstallation = window.location.pathname.replace(/\//gi, '') === 'hni'
  audio.mute(!isInstallation && isMuted)
  PlaybackController.play({ fade: 1.8 })
  viewer.startBenchmark()

  ReactGA.event({
    category: 'app',
    action: 'start',
  })
}

export const hideExplanation = () => async dispatch => {
  await dispatch({ type: constants.HIDE_EXPLANATION })
  dispatch(saveUser())
}

export const selectObject = name => async (dispatch, getState) => {
  const doIt = () => dispatch({
    type: constants.SELECT_OBJECT,
    data: {
      name,
    },
  })

  const { user, app } = getState()

  // if we already have camera permission we can dispatch early
  // this way webcam will fade in as well...
  if (app.isCameraReady) doIt()

  viewer.hasSelection = true
  viewer.fadeOutObjectsExcept(name)
  await PlaybackController.pause()

  // we only want to ask permission after fading out
  if (!app.isCameraReady) doIt()

  // in case the camera has not been initialised yet we show
  // an overlay explaning why we make photos
  if (!user.didSeeExplanation) {
    dispatch({ type: constants.SHOW_EXPLANATION })
  }

  ReactGA.event({
    category: 'app',
    action: 'selected object',
    label: name,
  })
}

export const unselectObject = () => async dispatch => {
  dispatch({
    type: constants.UNSELECT_OBJECT,
  })

  viewer.hasSelection = false
  viewer.fadeInObjects()
  PlaybackController.play()
}

export const handleAnimationTap = event => async (dispatch, getState) => {
  const { app } = getState()

  if (PlaybackController.isPlaying && !app.selectedObject) {
    const object = viewer.raycast(event)
    if (object) dispatch(selectObject(object.name))
  } else if (!PlaybackController.isPlaying && !app.selectedObject) {
    // only used in development
    PlaybackController.play()
  }
}

export const confirmAssignment = () => async dispatch => {
  dispatch({
    type: constants.CONFIRM_ASSIGNMENT,
  })
}

export const cancelAssignment = () => async dispatch => {
  dispatch({
    type: constants.CANCEL_ASSIGNMENT,
  })
}

export const submit = () => async (dispatch, getState) => {
  const startTime = Date.now()
  dispatch({ type: constants.UPLOAD_REQUEST })

  const state = getState()
  const { user, app, version } = state
  const instruction = getInstruction(state)

  const url = WebcamScene.canvasElement.toDataURL('image/jpeg', 0.75)
  const blob = dataURLtoBlob(url)

  const form = new FormData()
  form.set('user', user.id)
  form.set('object', app.selectedObject)
  if (version.id) form.set('versionId', version.id)
  if (instruction) form.set('questionId', instruction.groupId)
  form.append('image', blob)
  form.set('save', true)

  try {
    await axios.post(`${process.env.REACT_APP_API_URL}/contributions`, form, {
      // maybe useful for later
      // onUploadProgress: console.log,
    })

    // TODO: this should probably be improved
    TextureManager.textures[app.selectedObject] = url
    TextureManager.applyTexture(app.selectedObject)

    // to make sure the progress/uploading screen does not flicker
    const duration = Date.now() - startTime
    const minDuration = 3000
    const timeout = duration < minDuration ? minDuration - duration : 0
    setTimeout(async () => {
      await dispatch({ type: constants.UPLOAD_SUCCESS })
      dispatch(unselectObject())
      dispatch(saveUser())
    }, timeout)

    ReactGA.event({
      category: 'contribution',
      action: 'finished',
    })
  } catch (error) {
    console.error('error uploading contribution', error)
    Sentry.captureException(error)
    dispatch({ type: constants.UPLOAD_ERROR })
  }
}

export const setCameraReady = () => dispatch => {
  dispatch({
    type: constants.CAMERA_READY,
  })

  Sentry.configureScope(scope => {
    scope.setExtra('hasCameraPermission', true)
  })
}

// TODO: figure out how to reprompt permission
export const onCameraError = error => dispatch => {
  if (error.name === 'NotAllowedError') {
    Sentry.configureScope(scope => {
      scope.setExtra('hasCameraPermission', false)
    })

    ReactGA.event({
      category: 'camera',
      action: 'declined access',
    })

    dispatch({ type: constants.DECLINED_CAMERA })
  }
}

export const setLanguage = lang => async dispatch => {
  await dispatch({ type: constants.SET_LANGUAGE, data: lang })
  ReactGA.event({
    category: 'language',
    action: 'changed',
    value: lang,
  })
  dispatch(saveUser())
}

export const shareNatively = () => (dispatch, getState) => {
  const { copy, version } = getState()
  const translated = copy.translations[copy.lang]
  const formatted = version.name !== 'frontpage'
    ? translated['share.text.version']({
      name: slugToTitle(version.name),
      link: window.location.href,
    })
    : translated['share.text.default']

  return new Promise(async (resolve, reject) => {
    try {
      await navigator.share({
        title: 'Neuhaus',
        text: formatted,
        url: window.location.href,
      })
      ReactGA.event({
        category: 'share',
        action: 'success',
      })
      resolve()
    } catch (error) {
      // did not share
      ReactGA.event({
        category: 'share',
        action: 'cancelled',
      })
      reject(error)
    }
  })
}

// this share should only be called when animation is playing
export const share = () => async dispatch => {
  PlaybackController.pause()
  if (supportsWebShare()) {
    try {
      await dispatch(shareNatively())
    } catch (error) {
      // did not share
    } finally {
      PlaybackController.play()
    }
  } else {
    dispatch({ type: constants.SHOW_SHARE_OVERLAY, data: true })
  }
}

export const hideShare = () => dispatch => {
  dispatch({ type: constants.SHOW_SHARE_OVERLAY, data: false })
  PlaybackController.play()
  ReactGA.event({
    category: 'share',
    action: 'hide overlay',
  })
}

export const hideAbout = () => (dispatch, getState) => {
  const { version } = getState()
  const url = version.name === 'frontpage' ? '/' : `/${version.name}`
  history.push(url)
}

export const toggleSound = () => async (dispatch, getState) => {
  await dispatch({ type: constants.TOGGLE_SOUND })
  const { app } = getState()
  audio.mute(app.isMuted)
  dispatch(saveUser())

  ReactGA.event({
    category: 'sound',
    action: 'toggled',
    value: +app.isMuted,
  })
}
